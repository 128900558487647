import React from 'react'
import { graphql, Link } from 'gatsby'
import Select from 'react-select'
import Hero from '../components/hero'
import Layout from '../components/layout/layout'
import FeaturedBrandProfileCard from '../components/ui-kit/featuredBrandProfileCard'
import BrandProfileCard from '../components/ui-kit/brandProfileCard'
import SEO from '../components/layout/seo'
import { EmptyState, FilterWrapper, FilterContainer, FilterNav, GridContainer, MainContainer, HighlightsContainer, PickedContainer, PickedItemContainer, ViewProfileLink, PickedMetaContainer, PickedTitle, PickedItemWrapper, FeaturedTitleTop, HighlightsWrapper } from '../components/brand-directory/styled'
import { useBrandDirectory } from '../hooks/filters'
import { customSelectStyle } from '../styles/selectStyle'
import { capitalise, calculateDaysBetween } from '../utils/utils'
// import SharingImg from '../images/social_sharing_logo.png'

const BrandDirectoryPage = ({ data: {
  contentfulPageBrandDirectory: { hero, seoTitle, seoDescription, openGraphImage },
  allContentfulBrandProfile, featuredContent, pickedContent,
  directoryMeta
}, location }) => {
  const { filters, profiles } = useBrandDirectory(allContentfulBrandProfile, directoryMeta)

  const heroHeading = hero.heading;
  const heroSubheading = hero.subheading; 

  const heroCtaText = hero.ctaText;
  const heroCtaDestination = hero.ctaDestination; 
  const [featured] = featuredContent.edges;

  return (
    <Layout location={location}>
      <SEO
        pageTitle={seoTitle}
        seoDescription={seoDescription}
    
        // ogImage={openGraphImage.gatsbyImageData.images.fallback.src}
      />

      <Hero
        id="brand"
        heading={heroHeading}
        subheading={heroSubheading}
        ctaText={heroCtaText}
        ctaDestination={heroCtaDestination}
      />
      <HighlightsContainer>
        <FeaturedTitleTop>Featured Brand</FeaturedTitleTop>
          <HighlightsWrapper>

            <FeaturedBrandProfileCard profile={featured.node} />
        

          <PickedContainer>
            <PickedTitle>More picks</PickedTitle>
            <PickedItemWrapper>
            {pickedContent?.edges?.slice(0,3).map((item) => {
              const daysBetween = calculateDaysBetween(
                new Date(),
                new Date(item.node.createdAt)
              )

              return (
          
                <PickedItemContainer key={item.node.name}>
                  <div>
                    <Link to={item.node.slug}>
                      <h3>{item.node.name}</h3>
                    </Link>
                      <PickedMetaContainer>
                      <p>{item.node.bioExcerpt}</p>
                    
                    </PickedMetaContainer>
                    </div>

                    <ViewProfileLink><Link to={item.node.slug}>View profile</Link></ViewProfileLink>
                  </PickedItemContainer>
          
              )
            })}
                </PickedItemWrapper>
          </PickedContainer>
        </HighlightsWrapper>
      </HighlightsContainer>
      <MainContainer>
        <FilterWrapper>
          <FilterContainer>
            <FilterNav>
              <Select
                onChange={filters.categoriesChangeCb}
                options={filters.categories}
                styles={customSelectStyle}
                value={filters.selectedCategory}
              />
              <Select
                onChange={filters.tagsChangeCb}
                options={filters.tags}
                styles={customSelectStyle}
                value={filters.selectedTag}
              />
              <Select
                onChange={filters.platformChangeCb}
                options={filters.platforms}
                styles={customSelectStyle}
                value={filters.selectedPlatform}
              />
            </FilterNav>
          </FilterContainer>
        </FilterWrapper>
        {profiles.length > 0 ? (
          <GridContainer width={12}>
            {profiles?.map((profile) => (
              <BrandProfileCard key={profile.node.slug} profile={profile} />
            ))}
          </GridContainer>
        ) : (
          <EmptyState>
            <h2>No results</h2>
          </EmptyState>
        )}
      </MainContainer>

    </Layout>
  )
}

export default BrandDirectoryPage

export const brandDirectoryQuery = graphql`
  query BrandDirectoryQuery {
    contentfulPageBrandDirectory {
      seoTitle
      seoDescription
      openGraphImage {
        title
        gatsbyImageData(layout: FULL_WIDTH, quality: 85)
      }
      hero {
        heading
        subheading
        ctaText
        ctaDestination
      }
    }

    directoryMeta: allContentfulBrandProfile {
      categories: distinct(field: category)
      impactPrimary: distinct(field: primaryImpact)
      impactSecondary: distinct(field: secondaryImpact)
      location: distinct(field: locationData___locationText)
      platforms: distinct(field: platforms)
    }

    allContentfulBrandProfile(sort: { fields: [createdAt], order: ASC }) {
      edges {
        node {
          createdAt
          name
          slug
          logo {
            title
            gatsbyImageData(layout: CONSTRAINED, width: 55)
          }
          category
          primaryImpact
          secondaryImpact
          platforms
          locationData {
            locationText
          }
          
          bioExcerpt
        }
      }
    }
    featuredContent: allContentfulBrandProfile(
      sort: { fields: [createdAt], order: DESC }
      filter: { isFeatured: { eq: true } }
    ) {
      edges {
        node {
          createdAt
          name
          slug
          logo {
            title
            gatsbyImageData(layout: FULL_WIDTH)
          }
        
          category
          primaryImpact
          secondaryImpact
          platforms
          locationData {
            locationText
          }
          bioExcerpt
        }
      }
    }
    pickedContent: allContentfulBrandProfile(
      sort: { fields: [createdAt], order: DESC }
      filter: { isPicked: { eq: true } }
    ) {
      edges {
        node {
          createdAt
          name
          slug

          
          bioExcerpt
        }
      }
    }
  }
`
