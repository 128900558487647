import { Link } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import styled from "styled-components"
import { ANIMATION, BREAKPOINTS, LAYOUT } from "../../styles/cssVariables"
import Container from "../layout/container"
import Button from '../ui-kit/button'


export const HeroWrapper = styled.section`
  background-color: var(--color-skobeloff);
`

export const HeroContainer = styled(Container)`
  padding-top: 2rem;
  padding-bottom: 2rem;

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding-left: ${LAYOUT.gutterPage / 2}px;
    padding-right: ${LAYOUT.gutterPage / 2}px;
    > div {
      flex-direction: column
    }
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-left: ${LAYOUT.gutterPageMobile}px;
    padding-right: ${LAYOUT.gutterPageMobile}px;

}
`

export const HeroPanel = styled.div`
  width: 100%;
  @media (min-width: ${BREAKPOINTS.medUp}px) {
    position: relative;
    box-sizing: border-box;

    &:first-child {
      flex: 7;
      z-index: 1;
      align-self: center;
      @media (max-width: ${BREAKPOINTS.medUp}px) {
        text-align: center;
      }
    }
    &:last-child {
      flex: 4;
      align-self: center;
    }
  }
  &:first-child {
  
    @media (max-width: ${BREAKPOINTS.medUp}px) {
      text-align: center;
    }
  }
`

export const HeroHeading = styled.h1`
  margin-bottom: 25px;
  color: #fff;

`

export const HeroBody = styled.p`
  color: #fff;

  @media (min-width: ${BREAKPOINTS.medUp}px) {
    width: calc(5 / 8 * 100%);
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    margin-bottom: 0;
  }
`

export const HeroImage = styled(GatsbyImage)`
  @media (min-width: ${BREAKPOINTS.medUp}px) {
    width: 100%;
  }

  @media (max-width: ${BREAKPOINTS.med}px) {
    display: none;
  }
`
export const HighlightsContainer = styled.section`
  box-sizing: border-box;
  margin-top: 86px;
  
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  padding: 0 ${LAYOUT.gutterPage}px;
  width: 100%;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding: 0 ${LAYOUT.gutterPage / 2}px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 0;
    padding: 0 ${LAYOUT.gutterPageMobile}px;
  }
`
export const HighlightsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1.4fr;
  grid-template-rows: 1fr;
  grid-gap: 100px;
  @media (max-width: ${BREAKPOINTS.medUp}px) {
  
    grid-gap: 30px;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    grid-template-columns: 1fr;
  }
`



export const PickedContainer = styled.div`
  align-self: center;


`

export const PickedItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 30px;
`

export const FeaturedTitleTop = styled.h2`
  margin-bottom: 25px;
  font-size: 32px;
  line-height: 1.3;
  color: var(--color-heading);

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    font-size: 26px;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    font-size: 22px;
    line-height: 1.35;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-top: 50px;
  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    margin-top: 100px;
  }
`
export const PickedTitle = styled.h2`
  margin-bottom: 50px;
  font-size: 32px;
  line-height: 1.3;
  color: var(--color-heading);

  @media (max-width: ${BREAKPOINTS.lrg}px) {
    font-size: 26px;
  }
  @media (max-width: ${BREAKPOINTS.medUp}px) {
    font-size: 22px;
    line-height: 1.35;
  }
  @media (max-width: ${BREAKPOINTS.tbl}px) {
    margin-top: 50px; 
    margin-bottom: 25px;
  }
`



export const PickedItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 0.62;
  transition: opacity ${ANIMATION.speedMedium} ease;

  &::before {
    content: '';
    height: 100%;
    width: 4px;
    position: absolute;
    left: calc(-15px - 4px);
    background-color: var(--color-blue);
    opacity: 0;
    transition: opacity ${ANIMATION.speedMedium} ease;
  }


  &:hover,
  &:hover::before {
    opacity: 1;
  }

  > div a h3 {
    margin-bottom: 10px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    opacity: 1;
  }
  
`

export const PickedMetaContainer = styled.div`
  margin-bottom: 10px;
  font-size: 14px;

  p {
    font-size: 14px;
    color: var(--color-body);
  }
`

export const ViewProfileLink = styled(Button)`
    font-style: italic;
  margin-left: 0;
  margin-right: 0;
`

export const FilterWrapper = styled.div `
box-sizing: border-box;    
width: 100%;
  max-width: ${LAYOUT.pageWidth}px;
  margin: 0 auto;
  padding: 0 ${LAYOUT.gutterPage}px;

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    padding-left: ${LAYOUT.gutterPage / 2}px;
    padding-right: ${LAYOUT.gutterPage / 2}px;

  }
  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-left: ${LAYOUT.gutterPageMobile}px;
    padding-right: ${LAYOUT.gutterPageMobile}px;
  }

`

export const FilterContainer = styled.div`
  padding-top: 105px;
  margin-left: 0;
  margin-right: auto;
  
  width: 100%;

`

export const FilterNav = styled.nav`
  display: flex;

  @media (min-width: ${BREAKPOINTS.tblUp}px) {
    > * {
      flex: 1;

      &:not(:first-child) {
        margin-left: 40px;
      }
    }
  }

  @media (max-width: ${BREAKPOINTS.tbl}px) {
    flex-direction: column;

    > *:not(:first-child) {
      margin-top: 20px;
    }
  }
`

export const GridContainer = styled.div`
  box-sizing: border-box;
  padding-top: 60px;
  padding-bottom: calc(60px + 90px);
  max-width: ${LAYOUT.pageWidth}px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 200px;
  padding-left: ${LAYOUT.gutterPage}px;
  padding-right: ${LAYOUT.gutterPage}px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 26px 30px;
  

  @media (max-width: ${BREAKPOINTS.medUp}px) {
    grid-template-columns: 1fr 1fr;
    padding-left: ${LAYOUT.gutterPage / 2}px;
    padding-right: ${LAYOUT.gutterPage / 2}px;
  }

  @media (max-width: ${BREAKPOINTS.tbl}px) {
    padding-top: 40px;
    padding-bottom: 40px;
    grid-template-columns: 1fr;
    gap: 20px;  
    margin-bottom: 110px;
  }

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-left: ${LAYOUT.gutterPageMobile}px;
    padding-right: ${LAYOUT.gutterPageMobile}px;
  }
`

export const PaginationContainer = styled(Container)`
  > div {
    justify-content: center;
  }
`

export const Pagination = styled.nav`
  padding-bottom: 200px;

  @media (max-width: ${BREAKPOINTS.sml}px) {
    padding-bottom: 150px;
  }
`

export const PaginationLink = styled(Link)`
  height: 34px;
  width: 34px;

  display: inline-flex;
  justify-content: center;
  align-items: center;

  border-radius: 50%;

  font-size: 15px;
  color: var(--color-heading);

  ${(props) =>
    props.$isActive &&
    `
    background-color: var(--color-violet);
    color: #fff !important;
  `}
`

export const EmptyState = styled.div`
  text-align: center;
  > h3 {
    color: var(--color-body);
    margin-top: 64px;
  }
`

export const MainContainer = styled.section`
  min-height: 90vh;
`
